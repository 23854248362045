<template>
  <div class="popover-container">
    <div class="popover-header">
      <div>
        <span>{{ title }}</span> <br />
        <span>({{ titleDate }})</span>
      </div>
      <div @click="$emit('on-close')">
        <CloseIcon class="closeIcon" />
      </div>
    </div>

    <div class="popover-content">
      <div>
        <div v-for="{ key, value, discountPercent, itemName } in lastOrderIntegralItems" :key="key" class="list-item">
          <TruncatedText class="popover-content-name">
            {{ itemName ? itemName : $t(`orderProcessCart.OrderPriceInformationPopover.${key}`) }}
            {{ discountPercent ? `(${discountPercent}%)` : '' }}
          </TruncatedText>
          <div>{{ value }}</div>
        </div>
      </div>

      <span v-if="lastOrderAssociatedItems.length" class="seperator" />
      <div>
        <div v-for="{ key, value, discountPercent, itemName } in lastOrderAssociatedItems" :key="key" class="list-item">
          <TruncatedText class="popover-content-name">
            {{ itemName ? itemName : $t(`orderProcessCart.OrderPriceInformationPopover.${key}`) }}
            {{ discountPercent ? `(${discountPercent}%)` : '' }}
          </TruncatedText>
          <div>{{ value }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CloseIcon } from '@/assets/icons';
import { TruncatedText } from '@/modules/core';

export default {
  components: { CloseIcon, TruncatedText },
  props: {
    lastOrderIntegralItems: { type: Array, default: () => [] },
    lastOrderAssociatedItems: { type: Array, default: () => [] },
    title: { type: String, default: 'Default title' },
    titleDate: { type: String, default: null },
  },
};
</script>

<style lang="scss" scoped>
.popover-container {
  border-radius: 0.5rem !important;
  box-shadow: 0px 2px 8px 0px rgba(19, 26, 34, 0.25);

  .popover-header {
    display: flex;
    padding: 0.75rem;
    align-items: center;
    gap: var(--spacing-space-2, 0.5rem);
    align-self: stretch;
    border-bottom: 1px solid var(--outlines-outline, #d9dcde);
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
    color: var(--typography-primary, #1f284d);
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1rem;
    justify-content: space-between;

    .closeIcon {
      width: 1rem;
      height: 1rem;
      flex-shrink: 0;
      color: #9295a5;

      &:hover {
        color: inherit;
        cursor: pointer;
      }
    }
  }

  .popover-content {
    display: flex;
    padding: var(--spacing-space-3, 0.75rem);
    flex-direction: column;
    align-self: stretch;
    color: var(--typography-primary, #1f284d);

    .seperator {
      height: 1px;
      background: #d9dcde;
      margin: 0.75rem 0;
    }

    div {
      .list-item {
        margin-bottom: 0.5rem;
        ::v-deep * {
          font-size: 0.75rem !important;
        }
      }

      .popover-content-name {
        width: 8.75rem;
      }
      .list-item:last-of-type {
        margin-bottom: 0;
      }

      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      div:last-child {
        div:last-child {
          font-weight: 500;
        }
      }
    }
  }
}
</style>
