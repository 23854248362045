<template>
  <div class="order-info">
    <div class="info-row">
      <SupplierNewIcon :size="20" />
      <OrderArrivalTimePopover
        :order-id="orderId"
        :updated-at="updatedAt"
        :arrives-at="arrivesAt ?? closestArrivalDate"
        :supplier-order-terms="supplierOrderTerms"
        @on-cut-off-date-change="onCutOffDateChange"
        @on-arrives-at-updated="onArrivesAtUpdated"
      />
    </div>
    <div class="info-row">
      <ClockIcon :size="20" />
      <div v-if="isInThePast" class="d-flex gap-2 align-items-center">
        <p>{{ $t('orderProcessCart.cutOffTimePassed') }}</p>
        <el-tooltip
          popper-class="closest-arrival-tooltip"
          :content="$t('orderProcessCart.arrivalTimePopover.datePassedTooltip')"
          placement="top"
        >
          <WarningFullIcon :size="16" fill="#E52044" />
        </el-tooltip>
      </div>
      <div v-else class="d-flex gap-1">
        {{ $t('orderProcessCart.sendOrderIn') }}
        <Countdown
          v-if="cutOffTimeToDisplay"
          :date="cutOffTimeToDisplay"
          :only-time="true"
          @on-duration-change="$emit('on-duration-change')"
        />
      </div>
    </div>
    <div class="info-row">
      <PaymentsNisIcon v-if="isNis" :size="20" />
      <PaymentsUsdIcon v-else :size="20" />
      <p v-if="minimumOrderAmount">
        {{ $t('orderProcessCart.minimumOrderAmount') }}
        {{ formatCurrency(minimumOrderAmount) }}
      </p>
      <p v-else>
        {{ $t('orderProcessCart.noMinimumOrderAmount') }}
      </p>
    </div>
    <div v-if="openedByUser" class="info-row">
      <UserAvatar :user="openedByUser?.profile" :token="token" :opened-at="openedAt" :size="20" />
      <div>{{ `${$t('orders.table.headers.openedByUser')} ${fullName}` }}</div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { ClockIcon, SupplierNewIcon, PaymentsUsdIcon, PaymentsNisIcon, WarningFullIcon } from '@/assets/icons';
import { useCurrency } from '@/locale/useCurrency';

import Countdown from '@/modules/orderProcess/components/Countdown.vue';
import UserAvatar from '../../orderProcess/components/UserAvatar/UserAvatar.vue';
import OrderArrivalTimePopover from './OrderArrivalTimePopover.vue';

import {
  formatCurrency,
  formatCurrentDateTimeWithWeekday,
  formatDayAndTime,
  returnDashInsteadOfNull,
} from '@/modules/purchase-management/purchaseManagementFormatters';
import { computed } from 'vue';
import { useUser } from '@/modules/auth';

export default {
  components: {
    Countdown,
    ClockIcon,
    WarningFullIcon,
    SupplierNewIcon,
    PaymentsUsdIcon,
    PaymentsNisIcon,
    UserAvatar,
    OrderArrivalTimePopover,
  },
  props: {
    arrivesAt: { type: Date, default: null },
    closestArrivalDate: { type: Date, default: null },
    closestCutOffDate: { type: Date, default: null },
    minimumOrderAmount: { type: Number, default: null },
    openedByUser: { type: Object, default: null },
    orderId: { type: String, default: null },
    updatedAt: { type: Number, default: null },
    supplierOrderTerms: { type: Array, default: null },
    openedAt: { type: Number, default: null },
  },
  emits: ['on-duration-change', 'on-arrives-at-updated'],
  setup(props, { emit }) {
    const { currencyFormat } = useCurrency();
    const { token } = useUser();
    const cutOffTime = ref();
    const isInThePast = ref(false);

    const fullName = computed(() => `${props.openedByUser?.profile.firstName} ${props.openedByUser?.profile.lastName}`);

    const isNis = computed(() => currencyFormat.value.currency === 'ILS');

    const onCutOffDateChange = (newCutOff) => {
      if (!newCutOff) {
        // When emitting null, the date is in the past
        isInThePast.value = true;
      } else {
        isInThePast.value = false;
      }
      cutOffTime.value = newCutOff;
    };

    const cutOffTimeToDisplay = computed(() => {
      return cutOffTime.value ?? props.closestCutOffDate;
    });

    const onArrivesAtUpdated = (result) => emit('on-arrives-at-updated', result);

    return {
      token,
      isNis,
      fullName,
      isInThePast,
      cutOffTimeToDisplay,
      formatDayAndTime,
      onArrivesAtUpdated,
      onCutOffDateChange,
      formatCurrency: (value) =>
        returnDashInsteadOfNull(formatCurrency(value, { ...currencyFormat.value, maximumFractionDigits: 0 })),
      formatCurrentDateTimeWithWeekday: (date) =>
        returnDashInsteadOfNull(formatCurrentDateTimeWithWeekday(date, false)),
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
.order-info {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: -0.0125rem;
  width: fit-content;

  .info-row {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
}
</style>
<style lang="scss">
.closest-arrival-tooltip {
  &.el-tooltip__popper {
    max-width: 14rem;
    padding: 5px;
  }
}
</style>
