<template>
  <el-popover
    ref="popoverRef"
    popper-class="arrival-time-popover"
    trigger="click"
    @show="isPopoverVisible = true"
    @hide="isPopoverVisible = false"
  >
    <div slot="reference" class="d-flex align-items-center" :style="{ gap: '0.25rem', cursor: 'pointer' }">
      <el-tooltip
        popper-class="arrives-at-tooltip"
        placement="top"
        :content="$t('orderProcessCart.arrivalTimePopover.changeArrivesAt')"
      >
        <div class="d-flex align-items-center" :style="{ gap: '0.25rem', cursor: 'pointer' }">
          <span v-if="showForDeliveryText && $direction === 'rtl'" :style="isPopoverVisible ? { fontWeight: 500 } : {}">
            {{ $t('orderProcessCart.forDelivery') }}{{ valueFormatter(closeArrivalTime) }}
          </span>
          <span v-else-if="showForDeliveryText" :style="isPopoverVisible ? { fontWeight: 500 } : {}">
            {{ $t('orderProcessCart.forDelivery') }}
            {{ valueFormatter(closeArrivalTime) }}
          </span>
          <span v-else :style="isPopoverVisible ? { fontWeight: 500 } : {}">
            {{ formatDayDateAndTime(closeArrivalTime) }}
          </span>
          <ChevronIcon :size="20" :direction="isPopoverVisible ? 'up' : 'down'" />
        </div>
      </el-tooltip>
      <el-tooltip
        v-if="isInThePast"
        popper-class="arrives-at-tooltip"
        :content="$t('orderProcessCart.arrivalTimePopover.datePassedTooltip')"
        placement="top"
      >
        <WarningFullIcon v-if="isInThePast" :size="16" fill="#E52044" />
      </el-tooltip>
    </div>
    <div class="d-flex flex-column" :style="{ width: '14rem' }">
      <p class="popover-item" :style="{ fontWeight: '600' }">{{ $t('orderProcessCart.arrivalTimePopover.title') }}</p>
      <el-radio-group v-model="dateIndex" class="d-flex flex-column" @change="onDateChoose">
        <el-radio
          v-for="(dateOption, index) in arrivalDateOptions"
          :key="`arrivalDateOptions-${index}`"
          :label="index"
          class="popover-item"
        >
          {{ formatDayDateAndTime(dateOption) }}
        </el-radio>
      </el-radio-group>
    </div>
  </el-popover>
</template>
<script>
import { ref, computed, toRefs, watch } from 'vue';

import { ChevronIcon, WarningFullIcon } from '@/assets/icons';

import {
  formatDayAndTime,
  formatDayDateAndTime,
  returnDashInsteadOfNull,
} from '@/modules/purchase-management/purchaseManagementFormatters';
import {
  getDeliveryArrivalAndCutOffDates,
  isWithinAWeekFromNow,
} from '@/modules/purchase-management/purchaseManagement';

import { useAnalytics } from '../../../analytics/compositions/useAnalytics';
import { PUR_ANALYTICS_EVENT_TYPES } from '@/analytics/types/pur-analytics-types';
import { usePatchOrderProcess } from '../compositions/usePatchOrderProcess';

const PATCH_DEBOUNCE = 700;

export default {
  name: 'OrderArrivalTimePopover',
  components: { ChevronIcon, WarningFullIcon },
  props: {
    orderId: { type: String, default: null },
    updatedAt: { type: Number, default: null },
    arrivesAt: {
      type: Date,
      default: null,
    },
    supplierOrderTerms: { type: Array, default: null },
    showForDeliveryText: { type: Boolean, default: true },
  },
  emits: ['on-cut-off-date-change', 'on-arrives-at-updated'],
  setup(props, { emit }) {
    const popoverRef = ref(null);
    const dateIndex = ref(0);
    const supplierOrderTerms = toRefs(props).supplierOrderTerms;
    const arrivesAt = toRefs(props).arrivesAt;
    const isPopoverVisible = ref(false);
    const isInThePast = ref(false);
    const { patchOrderProcess, onDone } = usePatchOrderProcess(PATCH_DEBOUNCE);
    const { logEvent } = useAnalytics();

    const onDateChoose = (choice) => {
      popoverRef.value.doClose();
      dateIndex.value = choice;
      isInThePast.value = false;
      emit('on-cut-off-date-change', cutOffOptions.value[choice]);
      logEvent(PUR_ANALYTICS_EVENT_TYPES.ORDER_PROCESS.ARRIVAL_DATE, {
        orderId: props.orderId,
      });
      patchOrderProcess({
        orderId: props.orderId,
        patchPayload: { arrivesAt: arrivalDateOptions.value[choice] },
        updatedAt: props.updatedAt,
      });
    };

    onDone(({ data: { patchOrderProcessNew } }) => {
      emit('on-arrives-at-updated', { ...patchOrderProcessNew, arrivesAt: arrivalDateOptions.value[dateIndex.value] });
    });

    const arrivalAndCutOffDateOptions = computed(() =>
      supplierOrderTerms.value.length
        ? getDeliveryArrivalAndCutOffDates(supplierOrderTerms.value[0])
        : { arrivalDates: [], cutOffDates: [] }
    );

    const arrivalDateOptions = computed(() => arrivalAndCutOffDateOptions.value.arrivalDates);
    const cutOffOptions = computed(() => arrivalAndCutOffDateOptions.value.cutOffDates);

    const closeArrivalTime = computed(() => arrivalDateOptions.value[dateIndex.value] ?? arrivesAt.value);

    const nullableFormatDayDateAndTime = (date) => returnDashInsteadOfNull(formatDayDateAndTime(date));
    const nullableFormatDayAndTime = (date) => returnDashInsteadOfNull(formatDayAndTime(date));

    const valueFormatter = computed(() => {
      if (isWithinAWeekFromNow(closeArrivalTime.value) && !isInThePast.value) {
        return nullableFormatDayAndTime;
      }
      return nullableFormatDayDateAndTime;
    });

    watch(
      [arrivesAt, arrivalDateOptions, cutOffOptions],
      () => {
        if (arrivesAt.value && arrivalDateOptions.value.length && cutOffOptions.value.length) {
          const arrivalDateIndex = arrivalDateOptions.value.findIndex(
            (date) => date.toISOString().split('.')[0] === arrivesAt.value.toISOString().split('.')[0]
          );
          if (arrivalDateIndex === -1) {
            dateIndex.value = -1;
            isInThePast.value = true;
            emit('on-cut-off-date-change', null);
            return;
          } else {
            const cutOffTime = cutOffOptions.value[arrivalDateIndex];
            if (!cutOffTime || cutOffTime < new Date()) {
              isInThePast.value = true;
              emit('on-cut-off-date-change', null);
              return;
            }
          }
          dateIndex.value = arrivalDateIndex;
          emit('on-cut-off-date-change', cutOffOptions.value[arrivalDateIndex]);
        }
      },
      { immediate: true, deep: true }
    );

    return {
      dateIndex,
      popoverRef,
      isInThePast,
      arrivalDateOptions,
      valueFormatter,
      isPopoverVisible,
      closeArrivalTime,
      onDateChoose,
      formatDayDateAndTime: nullableFormatDayDateAndTime,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.popover-item {
  padding: 0.5rem;
  height: 2rem;
  color: $typography-primary;
}
</style>
<style lang="scss">
.arrival-time-popover.el-popover {
  padding: 0 !important;
}
.arrives-at-tooltip {
  &.el-tooltip__popper {
    max-width: 14rem;
    padding: 5px;
  }
}
</style>
